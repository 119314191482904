.k-block5 {
  overflow: hidden;
  padding-bottom: 125px;
}

.k-block5__title {
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
  margin-top:50px;
}
.k-block5__text {
  float:left;
  width:470px;
  font-size: 24px;
  font-weight: 300;
  margin-top:50px;
}
.k-block5__images {
  float:right;
  width:540px;
  margin: 45px 85px 0 0
}
.k-block5__images img {
  width:auto !important;
}