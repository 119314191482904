html, body {
  position:relative;
  //font-family: 'Open Sans', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-size:24px;
  font-weight:400;
  padding:0;
  margin:0;
  background:white;
  min-width:320px;
}

input, textarea, button {
  font-family: 'Roboto', sans-serif;
}

a, button {
  cursor:pointer;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  margin:0;
  padding:0;
  outline:0;
}

a, a:hover, a:visited, a:target {
  text-decoration:none;
  color:inherit;
}

img {
  max-width:100%;
}

.k-container {
  position: relative;
  width:1200px;
  margin:0 auto;
  clear: both;
}

.compensate-for-scrollbar {
  margin-right: 17px !important;
}