.k-block8 {
  height:590px;
  margin-top: 40px;
  background:#ffcc30;
}

.k-block8__bg {
  padding: 45px 20px 0 370px;
  height:590px;
  background:url("/img/bg1.png") left 20px bottom no-repeat;
}

.k-block8__title {
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
}
.k-block8__text {
  font-size: 24px;
  font-weight: 300;
  margin-top: 105px;
  padding-left: 110px;
}
.k-block8__button {
  display:block;
  height:64px;
  width:270px;
  margin:105px auto 0;
  line-height:62px;
  font-size: 24px;
  font-weight: 300;
  color:black;
  border-radius:3px;
  outline:none;
  background:transparent;
  border:1px solid black;
  text-transform: uppercase;
  text-align: center;
}
.k-block8__button:hover {
  color:white;
  background:black;
}