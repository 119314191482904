.k-block11 {
  height:660px;
  margin-top: 40px;
}

.k-block11__bg {
  padding: 10px 20px 0 370px;
  height:660px;
  background:url("/img/bg2.jpg") left 5px bottom no-repeat;
}

.k-block11__title {
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
}
.k-block11__text {
  font-size: 24px;
  font-weight: 300;
  margin-top: 45px;
  padding:0 30px 0 120px;
}
.k-block11__button {
  display:block;
  height:64px;
  width:420px;
  margin:45px auto 0;
  line-height:62px;
  font-size: 24px;
  font-weight: 300;
  color:black;
  border-radius:3px;
  outline:none;
  background:transparent;
  border:1px solid black;
  text-transform: uppercase;
  text-align: center;
}
.k-block11__button:hover {
  color:white;
  background:black;
}

.k-block11__phone {
  font-size: 50px;
  font-weight: 300;
  display: block;
  text-align: center;
  margin-top: 45px;
}
.k-block11__phone span {
  display:inline-block;
  border-bottom:1px solid black;
}

.k-block11__phone:hover span {
  border-color:white;
}
.k-block11__or {
  text-align: center;
  font-weight: 300;
  margin-top: 40px;
}