.k-block10 {
  padding: 50px 0 65px;
  margin-top: 70px;
  background:#ffcc30;
  overflow:hidden;
}

.k-block10__items {
  margin:65px 0 0;
}
.k-block10__item {
  padding:0 10px;
}
.k-block10__item img {
  width:100%;
}
.k-block10__title {
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
}

.k-block10__item-name {
  font-size: 20px;
  font-weight: 300;
  margin-top: 10px;
  white-space: nowrap;
}
.k-block10__item-desc {
  font-size: 18px;
  font-weight: 300;
  margin-top: 10px;
}
.k-block10__item-text {
  font-size: 18px;
  font-weight: 300;
  margin-top: 30px;
}

.k-block10 .owl-theme .owl-dots .owl-dot.active span {
  background: #000 !important;
  border-color: #000 !important;
}