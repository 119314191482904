@media (max-width:1220px) {

  .k-container {width:993px; padding-left:10px; padding-right:10px;}

  .k-header-top__menu {margin: 30px 0 0 20px;}
  .k-header-top__menu li {font-size:18px;}
  .k-header-top__advantages {font-size:20px;}
  .k-header-top__form {margin-right:10px;}

  .k-block2 {height:130px;}
  .k-block2__item1 {width: 400px; padding-right:30px;}
  .k-block2__item2 {width: 330px; padding-right:35px;}
  .k-block2__item3 {width: 230px;}

  .k-catalog__item {width:calc(~"33% - 20px");}
  .k-catalog__item-last {float: none; clear: both; margin:0 auto 100px;}

  .k-block4 {height:160px;}
  .k-block4__text {height:160px;background-position:left 70px center; padding: 15px 50px 0 200px;}

  .k-block5__text {float: none;width: 100%;margin-top: 30px;}
  .k-block5__images {float:none; margin: 50px auto 0;}

  .k-block6__form {padding: 110px 40px 90px 500px; background-position:top 90px left 10px;}

  .k-block8__bg {padding-left:300px;}
  .k-block8__text {margin-top: 100px; padding-left: 50px;}
  .k-block8__button {margin-top:80px;}

  .k-block9__images img:nth-child(1) {margin-left:0;}
  .k-block9__images img:nth-child(2) {margin-left:30px;}
  .k-block9__images img:nth-child(3) {margin-left:30px;}
  .k-block9__images img:nth-child(4) {margin-left:40px;}

  .k-block11__bg {padding-left: 360px;}
  .k-block11__text {margin-top:30px;}
  .k-block11__phone {margin-top:30px;}
  .k-block11__or {margin-top:30px; padding:0;}
  .k-block11__button {margin-top:30px;}

  .k-footer__menu {margin-left:30px;}
  .k-footer__info {margin-left:40px; width:320px;}
  .k-footer__counters {margin-right:0;}

}
@media (max-width:992px) {

  .k-container {width:769px;}

  .k-header-top__logo {float:none; clear:both;}
  .k-header-top__info {margin-top:-110px;}
  .k-header-top__menu {margin:30px 0; width:100%; text-align: center;}
  .k-header-top__menu li {margin:0 15px;}
  .k-header-top__title {padding-top:5px;}
  .k-header-top__advantages {width:380px; margin-top:40px;}

  .k-block2 {height:auto; overflow: hidden;}
  .k-block2__item1 {width: 480px;margin: 0 auto;float: none;clear: both;}
  .k-block2__item2 {width: 400px;padding-right: 35px;margin-left: 50px;}
  .k-block2__item3 {width: 270px;}

  .k-catalog__item {width:calc(~"50% - 20px");}
  .k-catalog__item-last {float: left; clear: none; margin: 0 10px 100px;}

  .k-block4__text {background-position: left center; padding: 15px 0 0 120px;}

  .k-block6__form {padding: 60px 40px 90px 310px;}

  .k-block8__bg {background-position:left bottom;}
  .k-block8__text {margin-top:30px;}

  .k-block9__images img:nth-child(1) {margin-left:130px;}
  .k-block9__images img:nth-child(2) {margin-left:80px;}
  .k-block9__images img:nth-child(3) {margin-left:90px; margin-top: 50px;}
  .k-block9__images img:nth-child(4) {margin-left:70px; margin-top: 50px;}

  .k-block11__bg {background-position:left bottom; padding-left: 250px;}
  .k-block11__title {font-size: 36px;}
  .k-block11__text {padding-left:220px; padding-right:0; margin-top:20px;}
  .k-block11__phone {padding-left:170px; margin-top:20px; font-size: 36px;}
  .k-block11__or {padding-left:150px;}
  .k-block11__button {width:350px; font-size:20px; margin-left:160px;}

  .k-footer {height:auto; padding:20px 0;}
  .k-footer__menu li {display:inline-block;}
  .k-footer__info {margin-left: 35px;width: 320px;margin-top: 10px;}
  .k-footer__counters {float: left;margin-top: -50px;width: 200px;}
  .k-footer__text {top:0; padding-top:40px;}

}
@media (max-width:768px) {

  .k-container {width:577px;}

  .k-header-top__logo {width: 320px; padding-left:110px;}
  .k-header-top__title-text {font-weight: 300; font-size: 48px;}
  .k-header-top__advantages {width: 100%; margin-top: 20px;font-size: 18px;}
  .k-header-top__advantages li::before {top:4px;}

  .k-header-top__form {left: 10px;right: 10px;width: auto;margin: 0;top: 590px; padding: 20px 0;}
  .k-header-top__form-title {font-size: 24px;font-weight: 300;margin-top: 10px;}
  .k-header-top__form-title br {display:none;}
  .k-header-top__form-input {float: left;margin: 10px 0 10px 10px; width: 250px;}
  .k-header-top__form-submit {float:right; margin: 10px 10px 10px 0;}
  .k-header-top__form-label {clear:both; margin-left:30px;}
  .k-header-top__form-checkbox {left:15px;}

  .k-block2__item1 {margin: 0;padding-left: 110px;width: 500px;padding-top: 20px;padding-bottom: 20px;}
  .k-block2__item2 {margin: 0;padding-left: 110px;width: 480px;background-position: left 25px center;padding-top: 20px;padding-bottom: 20px;}
  .k-block2__item3 {padding-left: 110px;background-position: left 15px center;padding-top: 30px;padding-bottom: 30px;width: 440px;}

  .k-catalog__title {font-size: 36px;}
  .k-catalog__desc {margin-top:20px;}
  .k-catalog__item-buttons-order,
  .k-catalog__item-buttons-info {width:130px;}

  .k-block4__text {font-size: 26px;}

  .k-block5__title {font-size: 36px;}

  .k-block6__title {font-size: 36px;}
  .k-block6__form {padding: 20px 40px 130px 110px;}

  .k-block7__title {font-size: 36px;}

  .k-block8__bg {padding-top:20px; padding-left:240px;}
  .k-block8__title {font-size:32px;}
  .k-block8__text {font-size:20px;}

  .k-block9__title {font-size: 36px;}
  .k-block9__images img:nth-child(1) {margin-left:50px;}
  .k-block9__images img:nth-child(2) {margin-left:80px;}
  .k-block9__images img:nth-child(3) {margin-left:30px;}
  .k-block9__images img:nth-child(4) {margin-left:40px;}

  .k-block10__title {font-size: 36px;}

  .k-block10__item img {width:auto !important; margin:0 auto;}
  .k-block10__item-name {width:300px; margin-left:auto; margin-right:auto;}
  .k-block10__item-desc {width:300px; margin-left:auto; margin-right:auto;}
  .k-block10__item-text {width:300px; margin-left:auto; margin-right:auto;}

  .k-block11 {height:auto; margin:40px 0;}
  .k-block11__bg {height:auto; background:none; padding:0 10px;}
  .k-block11__text {padding:0; text-align:center;}
  .k-block11__phone {padding:0; margin-top:40px;}
  .k-block11__or {padding:0;}
  .k-block11__button {margin:30px auto 0;}

  .k-footer__logo {width: 320px; padding-left:110px; margin: 0 auto 20px; float: none;}
  .k-footer__menu {float: none; margin:0; text-align:center;}
  .k-footer__info {margin-left:10px;}
  .k-footer__counters {float: right;margin-top: 15px;width: 180px;}
  .k-footer__text {padding-left:10px; padding-top:30px;}

  .k-popup__detail-info-images {text-align:center;}
  .k-popup__detail-info-images img {float:none; margin:0 auto;}
  .k-popup__detail-info-left {float:none; clear:both; padding-top:30px;}
  .k-popup__detail-info-right {float:none; clear:both; padding-top:30px;}
}
@media (max-width:576px) {

  .k-container {width:100%;}

  .k-header {height: auto;}
  .k-header-top__logo {max-width:100%; margin: 0 auto;}
  .k-header-top__info {float:none; text-align:center; width:300px; margin: 20px auto 0;}
  .k-header-top__info-phone {display:inline-block}
  .k-header-top__menu {margin:30px auto 10px; width:300px; float: none}
  .k-header-top__menu li {font-size:22px; font-weight:400;}
  .k-header-top__title-text {font-weight:400; font-size:28px; max-width:100%; line-height:40px; text-align:center;}
  .k-header-top__title-desc {font-size:20px; text-align:center;}
  .k-header-top__form {position:relative; top:0; margin:20px -10px 0; left:0; right:0; border-radius:0; padding:20px 10px; border-bottom: 5px solid black;}
  .k-header-top__form-title {margin-bottom:10px;}
  .k-header-top__form-input {margin:10px 0; width:100%;}
  .k-header-top__form-submit {margin:10px 0; width:100%;}
  .k-header-top__form-label {margin:10px 0; padding:0 0 0 20px;}
  .k-header-top__form-checkbox {left:5px;}

  .k-block2__item1,
  .k-block2__item2,
  .k-block2__item3 {width: 100%; padding: 70px 10px 10px; background-position: top 10px center; text-align: center;}

  .k-catalog__item {width:calc(~"100% - 20px");}

  .k-block4 {height:auto;}
  .k-block4__text {height:auto; background-position: top 20px center; padding: 110px 10px 10px;}

  .k-block5__text {font-size: 20px;}
  .k-block5__images {width:100%;}
  .k-block5__images .owl-dots .owl-dot span {margin:0 10px;}

  .k-block6__form {padding:20px 0; background: none;}
  .k-block6__form-label {padding-right:10px;}
  .k-block6__form-submit {padding:0; font-size:18px;}

  .k-block7__text {font-size:20px;}
  .k-block7__images img {float:none !important; margin-bottom:20px !important; width:100% !important;}


  .k-block8 {height: auto;}
  .k-block8__bg {height: auto; padding:40px 10px; background: none;}
  .k-block8__text {padding:0;}
  .k-block8__button {margin-top:40px;}

  .k-block9__images img {display:block !important; width:auto !important; margin:30px auto !important; float:none !important; max-width: 80%;}
  .k-block9__text {font-size:20px;}

  .k-block11__title {font-size:30px;}
  .k-block11__phone {font-size:30px;}
  .k-block11__button {width:100%; max-width:350px; font-size:18px;}

  .k-map__title {line-height: 50px; padding: 20px;}
  #k-map {height:400px;}

  .k-footer__logo {max-width:100%; margin: 0 auto;}
  .k-footer__menu {margin:30px auto 10px; width:300px; float: none}
  .k-footer__menu li {font-size:22px;}
  .k-footer__info {text-align: center; margin: 0 auto; float: none; max-width: 100%;}
  .k-footer__counters {float: none; width: 100%; text-align: center;}

  .k-popup {padding: 30px 10px 30px !important;}
}
