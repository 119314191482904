.k-block4 {
  height:110px;
  clear:both;
  background:#ffcc30;
  font-size:18px;
  font-weight:300;
}

.k-block4__text {
  height:110px;
  font-size:36px;
  text-transform: uppercase;
  background:url("/img/rst.png") left 30px center no-repeat;
  padding: 15px 0 0 100px;
  text-align:center;
}