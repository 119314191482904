.k-popup {
  display:none;
  width:600px !important;
  padding:70px 80px 60px !important;
}
.k-popup--detail {
  width:780px !important;
}
.k-popup--success {
  width:580px !important;
  padding:70px 80px 80px !important;
}

.k-popup__title {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.k-popup__desc {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
}

.k-popup__form {
  margin-top: 5px;
  color:black;
}
.k-popup__form input[type=checkbox],
.k-popup__form input[type=file] {
  display:none;
}

.k-popup__form-title {
  font-size: 30px;
  font-weight: 300;
  margin-top:60px;
}
.k-popup__form-input,
.k-popup__form-textarea {
  height:64px;
  width:100%;
  padding:15px;
  margin:20px 0 0;
  border-radius:2px;
  background:white;
  font-size: 18px;
  font-weight: 300;
  border:1px solid #b4b4b4;
  font-style:italic;
}
.k-popup__form-textarea {
  height:135px;
  resize:none;
}
.k-popup__form-submit {
  height:64px;
  width:285px;
  line-height:64px;
  padding:0 20px;
  margin-top:45px;
  font-size: 24px;
  font-weight: 300;
  color:black;
  background:#ffcc30;
  border-radius:3px;
  outline:none;
  border:none;
  text-align: center;
}
.k-popup__form-submit--mt {
  margin-top:30px;
  margin-bottom: -20px;
}
.k-popup__form-label {
  position: relative;
  display:block;
  cursor:pointer;
  margin-top:25px;
  padding:0 40px 0 20px;
}
.k-popup__form-file {
  display: inline-block;
  height:62px;
  width:280px;
  line-height:60px;
  padding:0 20px;
  margin:20px 0 0 -20px;
  font-size: 24px;
  font-weight: 300;
  color:white;
  background:black;
  border-radius:3px;
  outline:none;
  border:none;
  text-align: center;
}
.k-popup__form-file-text {
  display: block;
  margin-left: -20px;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 35px;
}
.k-popup__form-checkbox {
  position: absolute;
  left:5px;
  top:2px;
  display:block;
  height:10px;
  width:10px;
  border:1px solid black;
}

.k-popup__form-checkbox:after {
  content:"";
  position: absolute;
  top:-1px;
  left:0;
  display:block;
  height:4px;
  width:11px;
  border-left:1px solid black;
  border-bottom:1px solid black;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.k-popup__form-checkbox-text {
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  line-height:14px;
  text-align: left;
}

.k-popup__form-checkbox-text a {
  text-decoration: underline;
}

.k-popup__detail-info {
  font-size:18px;
  overflow:hidden;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}
.k-popup__detail-info strong {
  font-weight: 600;
}
.k-popup__detail-info-left {
  float:left;
}
.k-popup__detail-info-right {
  float:right;
}
.k-popup__detail-info-images {
  overflow:hidden;
}
.k-popup__detail-info-images img {
  float:left;
}

.k-popup__title-success {
  height:40px;
  line-height:40px;
  padding-left:50px;
  background:url("../img/popup-success-icon.png") left center no-repeat;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 20px;
}
.k-popup__desc-success {
  font-size: 18px;
  font-style: italic;
}
.k-popup__form-close-button {
  display: inline-block;
  height:62px;
  width:280px;
  line-height:60px;
  padding:0 20px;
  margin-top:60px;
  font-size: 24px;
  font-weight: 300;
  color:black;
  background:#ffcc30;
  border-radius:3px;
  outline:none;
  border:none;
  text-align: center;
  text-transform: lowercase;
}

.k-popup__button-order {
  display: inline-block;
  height:64px;
  width:285px;
  line-height:64px;
  padding:0 20px;
  margin-top:45px;
  font-size: 24px;
  font-weight: 300;
  color:black;
  background:#ffcc30;
  border-radius:3px;
  outline:none;
  border:none;
  text-align: center;
}