.k-footer {
  background:black;
  padding:20px 0 60px;
  height:250px;
}

.k-footer__logo {
  float:left;
  padding: 12px 0 12px 118px;
  width:340px;
  background:url("/img/logo-img.png") left 17px center no-repeat, #ffcc30;
  border-radius:4px;
  font-weight:300;
  color:black;
}

.k-footer__logo-title {
  text-transform:uppercase;
  font-size:48px;
  line-height:48px;
}
.k-footer__logo-desc {
  font-size:16px;
  line-height:16px;
  padding-left:3px;
}

.k-footer__menu {
  float:left;
  list-style:none;
  font-family: 'Open Sans', sans-serif;
  margin:5px 0 0 100px;
}

.k-footer__menu li {
  display:block;
  padding:0 5px;
  color:white;
  font-size:14px;
  margin-bottom:15px;
}
.k-footer__menu li a {
  text-decoration:underline;
}
.k-footer__menu li a:hover {
  text-decoration:none;
}

.k-footer__info {
  float: left;
  text-align: left;
  color: #fff;
  font-weight: 300;
  width: 350px;
  margin-left: 75px;
  margin-top: -5px;
}

.k-footer__info-phone {
  display: block;
  font-weight:300;
  font-size: 29px;
  color:#ffcc30;
  margin-bottom: 10px;
}
.k-footer__info-address {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
}
.k-footer__info-address a {
  text-decoration: underline;
}

.k-footer__counters {
  float:right;
  text-align:right;
  width:150px;
  margin:40px 40px 0 0;
}

.k-footer__text {
  display:block;
  clear:both;
  position: relative;
  top: -15px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
}