.k-catalog {
  text-align: center;
  padding-top:45px;
}

.k-catalog__title {
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 300;
  text-align: center;
}
.k-catalog__desc {
  display: inline-block;
  font-size: 24px;
  font-weight: 300;
  background:#ffcc30;
  padding:0 5px;
}

.k-catalog__list {
  margin:0 -10px;
  padding-top: 47px;
}

.k-catalog__item {
  position: relative;
  float:left;
  width:calc(~"25% - 20px");
  margin:0 10px 100px;
  border:1px solid white;
  padding-top: 13px;
}
.k-catalog__item:hover {
  border-color:#9b9b9b;
}
.k-catalog__item-last {
  border-color:white !important;
}


.k-catalog__item-title {
  height: 55px;
  font-size: 20px;
  font-weight: 300;
}
.k-catalog__item-image {
  text-align:center;
}
.k-catalog__item-desc {
  font-size: 14px;
  width:80%;
  height:70px;
  margin:3px auto 0;
  text-align:center;
}
.k-catalog__item-table {
  width:calc(~"100% + 2px");
  margin:0 -1px -1px;
  font-size: 16px;
  font-weight: 300;
  border-collapse: collapse;
}
.k-catalog__item-table td {
  border:1px solid #9b9b9b;
  height: 24px;
}
.k-catalog__item-table tr:nth-child(1) td,
.k-catalog__item-table tr:nth-child(2) td {
  background:#ffcc30;
}
.k-catalog__item-table tr:nth-child(4) td {
  background:#9b9b9b;
}
.k-catalog__item-buttons {
  position:absolute;
  top:100%;
  left:0;
  right:0;
  padding-top:10px;
}
.k-catalog__item-buttons-info {
  width:138px;
  height:42px;
  line-height:42px;
  float:left;
  font-size: 18px;
  font-weight: 300;
  border:1px solid black;
  border-radius:3px;
  color:black;
  background:white;
}
.k-catalog__item-buttons-info:hover {
  background:#9b9b9b;
  color:white;
}
.k-catalog__item-buttons-order {
  width:138px;
  height:42px;
  line-height:42px;
  float:right;
  font-size: 18px;
  font-weight: 300;
  border:1px solid black;
  border-radius:3px;
  color:black;
  background:#ffcc30;
}
.k-catalog__item-buttons-order:hover {
  background:black;
  color:white;
}

.k-catalog__item-title-last {
  font-size: 20px;
  font-weight: 300;
  background:#ffcc30;
  border-radius:3px;
  padding:7px 20px;
  margin-top:-18px;
}
.k-catalog__item-image-last {
  margin-top:5px;
}
.k-catalog__item-image-last img {
  display:block;
  margin:0 auto;
}
.k-catalog__item-phone-last {
  font-size:16px;
  text-align:center;
  background:#ffcc30;
  font-weight: 300;
  padding:14px;
  margin-top:-1px;
  border-radius:3px;
}
.k-catalog__item-phone-last a {
  display:block;
  font-size:26px;
  font-weight: 300;
  margin-top:2px;
}
.k-catalog__item-phone-last a span {
  display:inline-block;
  border-bottom:1px solid black;
}
.k-catalog__item-phone-last:hover a span {
  border-color:#ffcc30;
}
.k-catalog__item-desc-last {
  text-align:center;
  font-size: 14px;
  margin-top: 15px;
}
.k-catalog__item-button-last {
  position:absolute;
  top:100%;
  left:0;
  right:0;
  margin-top:7px;
  height:42px;
  line-height:42px;
  font-size: 18px;
  font-weight: 300;
  border:1px solid black;
  border-radius:3px;
  color:black;
  background:#ffcc30;
}
.k-catalog__item-button-last:hover {
  background:black;
  color:white;
}