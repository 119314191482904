.k-block7 {
  margin-top: 30px;
}

.k-block7__title {
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
}
.k-block7__text {
  font-size: 24px;
  font-weight: 300;
  margin-top: 30px;
}

.k-block7__images {
  margin-top: 50px;
}

.k-block7__images img:first-of-type {
  float:left;
  width: 50%;
  margin-bottom:40px;
}

.k-block7__images img:last-of-type {
  float:right;
  width: 50%;
  margin-bottom:40px;
}

.k-block7__button {
  display:block;
  clear:both;
  height:64px;
  width:270px;
  margin:0 auto 0;
  line-height:62px;
  font-size: 24px;
  font-weight: 300;
  text-align:center;
  color:black;
  border-radius:3px;
  outline:none;
  background:#ffcc30;
  border:1px solid black;
}
.k-block7__button:hover {
  color:white;
  background:black;
}