.k-block9 {
  padding-top: 50px;
}

.k-block9__title {
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
}
.k-block9__text {
  font-size: 24px;
  font-weight: 300;
  margin-top: 20px;
}
.k-block9__images {
  margin-top:40px;
}
.k-block9__images img {
  display:inline-block;
  vertical-align: middle;
}

.k-block9__images img:nth-child(1) {
  margin-left:20px;
}
.k-block9__images img:nth-child(2) {
  margin-left:85px;
}
.k-block9__images img:nth-child(3) {
  margin-left:120px;
}
.k-block9__images img:nth-child(4) {
  margin-left:80px;
}