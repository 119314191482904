.k-map {

}

.k-map__title {
  background:#ffcc30;
  line-height:150px;
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
}

#k-map {
  height:800px;
}