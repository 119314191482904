.k-block6 {
  height:670px;
  padding-top: 45px;
  background:#ffcc30;
}

.k-block6__title {
  text-transform: uppercase;
  text-align:center;
  font-size: 48px;
  font-weight: 300;
}
.k-block6__desc {
  text-align:center;
  font-size: 24px;
  font-weight: 300;
}

.k-block6__form {
  padding: 110px 120px 90px 660px;
  color:black;
  text-align:center;
  background:url("/img/item-free.png") top 90px left 60px no-repeat;
}
.k-block6__form form {

}
.k-block6__form input[type=checkbox] {
  display:none;
}

.k-block6__form-input {
  height:64px;
  width:100%;
  padding:15px;
  margin:10px 0 5px;
  border:none;
  border-radius:3px;
  background:white;
  font-size: 18px;
  font-weight: 300;
}
.k-block6__form-submit {
  height:64px;
  width:100%;
  margin-top:50px;
  line-height:62px;
  padding:0 20px;
  font-size: 24px;
  font-weight: 300;
  color:black;
  border-radius:3px;
  outline:none;
  background:transparent;
  border:1px solid black;
  cursor:pointer;
}
.k-block6__form-submit:hover {
  color:white;
  background:black;
}
.k-block6__form-label {
  position: relative;
  display:block;
  cursor:pointer;
  margin-top:25px;
  padding:0 40px 0 20px;
}
.k-block6__form-checkbox {
  position: absolute;
  left:5px;
  top:2px;
  display:block;
  height:10px;
  width:10px;
  border:1px solid black;
}

.k-block6__form-checkbox:after {
  content:"";
  position: absolute;
  top:-1px;
  left:0;
  display:block;
  height:4px;
  width:11px;
  border-left:1px solid black;
  border-bottom:1px solid black;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.k-block6__form-checkbox-text {
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  line-height:14px;
  text-align: left;
}

.k-block6__form-checkbox-text a {
  text-decoration: underline;
}