.k-header {
  height:800px;
  background:url("/img/top-infoblock.jpg") top center no-repeat, #ffcc30;
}

.k-header-top {
  padding-top:16px;
}

.k-header-top__logo {
  float:left;
  padding: 12px 0 12px 118px;
  width:340px;
  background:url("/img/logo-img.png") left 17px center no-repeat, #ffcc30;
  border-radius:4px;
  font-weight:300;
  color:black;
}

.k-header-top__logo-title {
  text-transform:uppercase;
  font-size:48px;
  line-height:48px;
}
.k-header-top__logo-desc {
  font-size:16px;
  line-height:16px;
  padding-left:3px;
}

.k-header-top__menu {
  float:left;
  list-style:none;
  font-family: 'Open Sans', sans-serif;
  margin:25px 0 0 70px;
}

.k-header-top__menu li {
  display:inline-block;
  padding:0 2px;
  color:white;
  font-weight: 300;
  font-size:20px;
  margin-right:10px;
  border-bottom:4px solid transparent;
}
.k-header-top__menu li:hover {
  border-color: #ffcc30;
}

.k-header-top__info {
  float:right;
  text-align: right;
  color:white;
  font-weight:300;
}

.k-header-top__info-address {
  display: inline-block;
  font-size: 18px;
}
.k-header-top__info-phone {
  display: block;
  font-size: 26px;
  border-bottom:1px solid white;
}
.k-header-top__info-callback {
  display: inline-block;
  font-size: 16px;
  border-bottom:1px solid white;
  margin-top:20px;
}

.k-header-top__title {
  padding-top:100px;
  color:white;
  clear: both;
}
.k-header-top__title-text {
  font-weight:100;
  text-transform: uppercase;
  font-size: 60px;
  line-height: 60px;
  width:500px;
}
.k-header-top__title-desc {
  font-weight:300;
  font-size: 26px;
  margin-top:5px;
}

.k-header-top__advantages {
  list-style-type: none;
  color:white;
  font-size: 24px;
  font-weight: 300;
  margin-top:75px;
}

.k-header-top__advantages li {
  position:relative;
  margin-bottom:12px;
  padding-left:40px;
}
.k-header-top__advantages li:before {
  content:"";
  position:absolute;
  left:0;
  top:8px;
  display: block;
  height:8px;
  width:22px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);

  border-left: 2px solid #ffcc30;
  border-bottom: 2px solid #ffcc30;
}

.k-header-top__form {
  position:absolute;
  top:225px;
  right:0;
  width:370px;
  margin:0 35px 0 0;
  padding-bottom:55px;
  background:#ffcc30;
  border-radius:4px;
  color:black;
  text-align:center;
}
.k-header-top__form input[type=checkbox] {
  display:none;
}

.k-header-top__form-title {
  font-size: 30px;
  font-weight: 300;
  margin-top:60px;
}
.k-header-top__form-input {
  height:64px;
  width:310px;
  padding:15px;
  margin:40px 0 35px;
  border:none;
  border-radius:3px;
  background:white;
  font-size: 18px;
  font-weight: 300;
}
.k-header-top__form-submit {
  height:64px;
  line-height:64px;
  padding:0 20px;
  font-size: 24px;
  font-weight: 300;
  color:white;
  background:black;
  border-radius:3px;
  outline:none;
  border:1px solid black;
}
.k-header-top__form-submit:hover {
  color:black;
  background:#ffcc30;
}
.k-header-top__form-label {
  position: relative;
  display:block;
  cursor:pointer;
  margin-top:40px;
  padding:0 40px 0 60px;
}
.k-header-top__form-checkbox {
  position: absolute;
  left:45px;
  top:2px;
  display:block;
  height:10px;
  width:10px;
  border:1px solid black;
}

.k-header-top__form-checkbox:after {
  content:"";
  position: absolute;
  top:-1px;
  left:0;
  display:block;
  height:4px;
  width:11px;
  border-left:1px solid black;
  border-bottom:1px solid black;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.k-header-top__form-checkbox-text {
  display: inline-block;
  font-size: 12px;
  font-weight: 300;
  line-height:14px;
  text-align: left;
}

.k-header-top__form-checkbox-text a {
  text-decoration: underline;
}