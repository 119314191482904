.k-block2 {
  height:110px;
  clear:both;
  background:#ffcc30;
  font-size:18px;
  font-weight:300;
}
.k-block2__item1 {
  float:left;
  width: 510px;
  padding:35px 60px 35px 90px;
  background:url("/img/info1.png") left 20px center no-repeat;
}
.k-block2__item2 {
  float:left;
  width: 410px;
  padding:35px 50px 35px 55px;
  background:url("/img/info2.png") left center no-repeat;
}
.k-block2__item3 {
  float:left;
  width: 260px;
  padding:35px 0 35px 85px;
  background:url("/img/info3.png") left center no-repeat;
}